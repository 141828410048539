import {createAction, props} from '@ngrx/store';
import {Mode} from '../../modules/components/vorlaufzeile/mode.type';
import {FakturierungsbelegDTO, PositionDTO} from '../../openapi/fakturierung-openapi';


export class FakturierungsbelegFormActions {

  public static reset = createAction(
    '[Fakturierungsbeleg Form] Reset this context.',
  );

  public static updateFakturierungsbelegFormDto = createAction(
    '[Fakturierungsbeleg Form] Updated the selected fakturierungsbeleg data.',
    props<{
      fakturierungsbelegDto: FakturierungsbelegDTO,
    }>(),
  );

  public static setVorlaufzeileMode = createAction(
    '[Fakturierungsbeleg Form] Change Vorlaufzeile Mode.',
    props<{
      inhaberId: string,
      mode: Mode,
    }>(),
  );

  public static setVorlaufzeileText = createAction(
    '[Fakturierungsbeleg Form] Set Vorlaufzeile Text.',
    props<{
      inhaberId: string,
      text: string,
    }>(),
  );

  public static selectLeistungsempfaenger = createAction(
    '[Fakturierungsbeleg Form] Select Leistungsempfänger',
    props<{
      inhaberId?: string,
      absenderId?: string,
    }>(),
  );

  public static removePositionByNumber = createAction(
    '[Fakturierungsbeleg Form] Remove Position',
    props<{
      fakturierungsbelegId: string,
      number: number,
    }>(),
  );

}

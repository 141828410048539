import {Component, OnInit} from '@angular/core';
import {AppState} from '../../../store/states/app.state';
import {Store} from '@ngrx/store';
import {FakturierungsbelegEntitiesSelectors} from '../../../store/selectors/fakturierungsbeleg-entities.selectors';
import {
  DeleteFakturierungsbelegDialogSelectors
} from '../../../store/selectors/delete-fakturierungsbeleg-dialog.selectors';
import {take} from 'rxjs';
import {FakturierungsbelegDTO} from '../../../openapi/fakturierung-openapi';
import {DeleteFakturierungsbelegDialogActions} from '../../../store/actions/delete-fakturierungsbeleg-dialog.actions';
import {NGXLogger} from 'ngx-logger';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FakturierungsbelegFormSelectors} from '../../../store/selectors/fakturierungsbeleg-form.selectors';


@Component({
  selector: 'app-delete-fakturierungsbeleg-dialog',
  templateUrl: './delete-fakturierungsbeleg-dialog.component.html',
  styleUrls: ['./delete-fakturierungsbeleg-dialog.component.scss']
})
export class DeleteFakturierungsbelegDialogComponent implements OnInit {

  protected fakturierungsbelegDto?: FakturierungsbelegDTO;

  constructor(
    private store: Store<AppState>,
    private logger: NGXLogger,
    private snackbar: MatSnackBar,
  ) {
  }

  ngOnInit() {
    this.store.select(DeleteFakturierungsbelegDialogSelectors.deleteData).pipe(
      take(1),
    ).subscribe(data => {

      if (data.fakturierungbelegId) {
        this.store.select(FakturierungsbelegFormSelectors.fakturierungsbelegFormDtoById(
          data.fakturierungbelegId,
        )).pipe(
          take(1),
        ).subscribe(fakturierungsbelegDto => {
          this.fakturierungsbelegDto = fakturierungsbelegDto;
        });
      } else {
        this.logger.warn('delete dialog fakturierungsbelegId is not set');
      }
    });
  }

  protected deleteFakturierungsbelegClick(): void {
    this.snackbar.open('Rechnung löschen wurde geklickt', undefined, {
      duration: 1000,
    });

    this.store.dispatch(DeleteFakturierungsbelegDialogActions.close());
  }

  protected closeDialogClick(): void {
    this.store.dispatch(DeleteFakturierungsbelegDialogActions.close());
  }
}

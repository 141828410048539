import {FakturierungsbelegFormState} from '../states/fakturierungsbeleg-form.state';
import {createReducer, on} from '@ngrx/store';
import {FakturierungsbelegFormActions} from '../actions/fakturierungsbeleg-form.actions';
import {FakturierungsbelegFormDto} from '../../interfaces/fakturierungsbeleg-form-dto.interface';


export const initialFakturierungsbelegFormState: FakturierungsbelegFormState = {
  fakturierungsbelegFormDtos: [],
};

export const fakturierungsbelegFormReducer = createReducer(
  initialFakturierungsbelegFormState,

  on(FakturierungsbelegFormActions.reset, () => initialFakturierungsbelegFormState),

  on(FakturierungsbelegFormActions.updateFakturierungsbelegFormDto, (state, {fakturierungsbelegDto}) => {
    const fakturierungsbelegFormDtos = [...state.fakturierungsbelegFormDtos];

    // INFO: Entfernen des aktuellen Eintrags mit der passenden `inhaberId`, falls vorhanden
    const index = fakturierungsbelegFormDtos.findIndex(item => item.inhaberId === fakturierungsbelegDto.inhaberId);
    if (index !== -1) {
      fakturierungsbelegFormDtos.splice(index, 1);
    }

    const formDto: FakturierungsbelegFormDto = {
      ...fakturierungsbelegDto,
      vorlaufzeileMode: 'display',
    };

    // INFO: Hinzufügen des neuen Fakturierungsbeleg hinzu
    fakturierungsbelegFormDtos.push(formDto);

    return {
      ...state,
      fakturierungsbelegFormDtos,
    };
  }),

  on(FakturierungsbelegFormActions.selectLeistungsempfaenger, (state, {inhaberId, absenderId}) => {
    const fakturierungsbelegFormDtos = [...state.fakturierungsbelegFormDtos];

    const index = fakturierungsbelegFormDtos.findIndex(item => item.inhaberId === inhaberId);
    if (index !== -1) {
      const dto = fakturierungsbelegFormDtos[index];
      fakturierungsbelegFormDtos[index] = {
        ...dto,
        absenderId,
      };
    }

    return {
      ...state,
      fakturierungsbelegFormDtos,
    };
  }),

  on(FakturierungsbelegFormActions.setVorlaufzeileMode, (state, {inhaberId, mode}) => {
    const fakturierungsbelegFormDtos = [...state.fakturierungsbelegFormDtos];

    const index = fakturierungsbelegFormDtos.findIndex(item => item.inhaberId === inhaberId);
    if (index !== -1) {
      const dto = fakturierungsbelegFormDtos[index];
      fakturierungsbelegFormDtos[index] = {
        ...dto,
        vorlaufzeileMode: mode,
      };
    }

    return {
      ...state,
      fakturierungsbelegFormDtos,
    };
  }),

  on(FakturierungsbelegFormActions.setVorlaufzeileText, (state, {inhaberId, text}) => {
    const fakturierungsbelegFormDtos = [...state.fakturierungsbelegFormDtos];

    const index = fakturierungsbelegFormDtos.findIndex(item => item.inhaberId === inhaberId);
    if (index !== -1) {
      const dto = fakturierungsbelegFormDtos[index];
      fakturierungsbelegFormDtos[index] = {
        ...dto,
        vorlaufzeile: text,
      };
    }

    return {
      ...state,
      fakturierungsbelegFormDtos,
    };
  }),

  on(FakturierungsbelegFormActions.removePositionByNumber, (state, {
    fakturierungsbelegId,
    number,
  }) => {
    const fakturierungsbelegFormDtos = [...state.fakturierungsbelegFormDtos];

    const index = fakturierungsbelegFormDtos.findIndex(item => item.id === fakturierungsbelegId);
    if (index !== -1) {
      const dto = fakturierungsbelegFormDtos[index];

      // INFO: Entfernen der Position
      let positionen = dto.positionen!.filter(position => position.nummer !== number);

      // INFO: Anpassen der Positionen-Nummern
      positionen = positionen.map(position => {
        if (position.nummer > number) {
          return {
            ...position,
            nummer: position.nummer - 1
          };
        }
        return position;
      });

      fakturierungsbelegFormDtos[index] = {
        ...dto,
        positionen,
      };
    }

    return {
      ...state,
      fakturierungsbelegFormDtos,
    };
  }),
);

import {createSelector} from '@ngrx/store';
import {identity} from 'rxjs';
import {AbsenderEntitiesSelectors} from './absender-entities.selectors';
import {AppState} from '../states/app.state';
import {ProduktEntitiesSelectors} from './produkt-entities.selectors';


export class FakturierungsbelegFormSelectors {
  /**
   * Die `select`-Eigenschaft ist ein Objekt, das Funktionen definiert, jede Funktion selektiert eine
   * bestimmte Eigenschaft aus dem State.
   * Das sind grundlegende Selektoren, die im ganzen Projekt als Bausteine für komplexere Selektoren wiederverwendet
   * werden können.
   */
  private static select = {
    fakturierungsbelegFormDtos: (state: AppState) => state.fakturierungsbelegForm.fakturierungsbelegFormDtos,
  };

  /**
   * Selektor, der einen Fakturierungsbeleg anhand seiner ID zurückgibt.
   * Der Fakturierungsbeleg enthält auch die zugehörigen Positionen und Produkte.
   * Diese werden direkt aus den ProduktEntities abgeleitet.
   *
   * @param fakturierungsbelegId
   */
  public static fakturierungsbelegFormDtoById = (fakturierungsbelegId: string) => createSelector(
    FakturierungsbelegFormSelectors.select.fakturierungsbelegFormDtos,
    ProduktEntitiesSelectors.all,
    (fakturierungsbelegFormDtos, produktDtos) => {
      // INFO: Hier wird der Fakturierungsbeleg anhand seiner ID aus der Liste der Belege gefiltert.
      const fakturierungsbelegFormDto = {
        ...fakturierungsbelegFormDtos.find(beleg => beleg.id === fakturierungsbelegId)
      };

      if (!fakturierungsbelegFormDto) return undefined;

      // INFO: Hier werden die Produkte zu den Positionen geladen und dem FakturierungsbelegFormDtos hinzugefügt.
      for (const position of fakturierungsbelegFormDto.positionen || []) {
        const produktDto = produktDtos.find(produktDto => produktDto.id === position.produktId);
        if (produktDto) {
          fakturierungsbelegFormDto.produkteZuPositionen = [
            ...fakturierungsbelegFormDto.produkteZuPositionen || [],
            produktDto,
          ];
        }
      }

      return fakturierungsbelegFormDto;
    },
  );

  public static absenderDto = (inhaberId: string) => createSelector(
    FakturierungsbelegFormSelectors.select.fakturierungsbelegFormDtos || [],
    AbsenderEntitiesSelectors.all,
    (fakturierungsbelege, absenderDtos) => {
      if (!fakturierungsbelege) return undefined;

      const beleg = Array.from(fakturierungsbelege!).find(item => item.inhaberId === inhaberId);

      return AbsenderEntitiesSelectors.absenderById(beleg?.absenderId || '').projector(absenderDtos);
    }
  );

  /**
   * Selektiert die Positionen des Fakturierungsbelegs
   */
  public static positionen = (inhaberId: string) => createSelector(
    FakturierungsbelegFormSelectors.select.fakturierungsbelegFormDtos,
    fakturierungsbelege => {
      const beleg = Array.from(fakturierungsbelege!).find(item => item.inhaberId === inhaberId);

      return beleg?.positionen || [];
    }
  );

  /**
   * Selektiert den Modus der Vorlaufzeile, für den einen Fakturierungsbeleg zu dem Inhaber
   */
  public static vorlaufzeileMode = (inhaberId: string) => createSelector(
    FakturierungsbelegFormSelectors.select.fakturierungsbelegFormDtos,
    fakturierungsbelege => {
      const beleg = Array.from(fakturierungsbelege!).find(formDto => {
        return formDto.inhaberId === inhaberId;
      });

      return beleg?.vorlaufzeileMode;
    }
  );

  /**
   * Selektiert den Text der Vorlaufzeile, für den einen Fakturierungsbeleg zu dem Inhaber
   */
  public static vorlaufzeileText = (inhaberId: string) => createSelector(
    FakturierungsbelegFormSelectors.select.fakturierungsbelegFormDtos,
    fakturierungsbelege => {
      const beleg = Array.from(fakturierungsbelege!).find(item => {
        return item.inhaberId === inhaberId;
      });

      return beleg?.vorlaufzeile || '';
    }
  );


}
